<template>
  <div>
    <Loading></Loading>
    <global-header></global-header>
    <router-view name="header"></router-view>
    <router-view id="router" v-if="!page.initDataFetching"></router-view>
    <DefaultFooter></DefaultFooter>
  </div>
</template>

<script>
  import Loading from '@/components/common/Loading'
  import {mapState} from 'vuex'
  import GlobalHeader from "../components/main/header/GlobalHeader";
  import DefaultFooter from "../components/main/footer/DefaultFooter";

export default {
  name: 'Index',
  components: {
    DefaultFooter,
    Loading,
    GlobalHeader
  },
  computed: {
    ...mapState(['page']),
  },
}
</script>
<style>
  #router {
    min-height: calc(100vh - 80px - 340px); /* 100vh - 헤더높이 - 푸터높이 */
    margin-top: 80px;
  }
  @media (max-width: 768px) {
    #router {
      min-height: calc(100vh - 30px - 245px);
      margin-top: 49px;
    }
  }
</style>
